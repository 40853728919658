import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import styled from "styled-components"
import { mq, colors } from "../utils/presets"
import Timeline from "../components/Timeline"
import SubpageTopimage from "../components/SubpageTopimage"

const Title = styled.h1``

const Wrapper = styled.div`
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  ${mq.tablet} {
    max-width: 1080px;
    margin: 0 auto;
    /* border-bottom: 3px solid ${colors.black}; */
    margin-bottom: 3rem;
  }
`

const Textwrapper = styled.div`
  padding: 0.8rem;
  flex: 1 0 auto;
  width: 100%;
  h1 {
    padding: 1rem 0;
  }
  ${mq.tablet} {
    padding: 1rem 1rem 0;
    width: 35%;
    margin-right: 1rem;
    h1 {
      padding: 0;
      text-align: left;
      border-bottom: none;
      margin-bottom: 0;
      margin-right: 1rem;
    }
  }
  ${mq.tablet} {
    margin-right: 0;
    padding-right: 0;
  }
`

const ContactPageTemplate = ({ data }) => (
  <Layout>
    <SEO
      title={data.wordpressPage.title}
      description={data.wordpressPage.excerpt}
    />
    <SubpageTopimage
      objectFit="cover"
      objectPosition="50% 50%"
      fluid={data.wordpressPage.featured_media.localFile.childImageSharp.fluid}
      alt={data.wordpressPage.title}
    />
    <Wrapper>
      <Textwrapper>
        <Title>{data.wordpressPage.title}</Title>
        <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.content }} />
        <Timeline />
      </Textwrapper>
    </Wrapper>
  </Layout>
)
export default ContactPageTemplate

export const query = graphql`
  query($id: Int!) {
    wordpressPage(wordpress_id: { eq: $id }) {
      title
      excerpt
      content
      featured_media {
        localFile {
          childImageSharp {
            fluid(maxWidth: 1920) {
              ...GatsbyImageSharpFluid_tracedSVG
            }
          }
        }
      }
      acf {
        timeline_page {
          jahreszahl
          text
        }
      }
    }
  }
`
